import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import Seo from "../components/Seo";

// eslint-disable-next-line
export const AboutPageTemplate = ({ title, content, contentComponent }) => {
	const PageContent = contentComponent;

	return (
		<section className="section ">
			<Seo title={title} article={false} />
			<div className="gecko-wrapper">
				<div className="grid grid-cols-12 gap-8 mt-12">
					<div className="col-span-full md:col-span-8 prose md:col-start-3">
						<h1>{title}</h1>
						<PageContent className="content" content={content} />
					</div>
				</div>
			</div>
		</section>
	);
};

AboutPageTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
	contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<AboutPageTemplate
				contentComponent={HTMLContent}
				title={post.frontmatter.title}
				content={post.htmlAst}
			/>
		</Layout>
	);
};

AboutPage.propTypes = {
	data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
	query AboutPage($id: String!, $language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		markdownRemark(id: { eq: $id }) {
			htmlAst
			frontmatter {
				title
			}
		}
	}
`;
